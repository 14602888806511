import React, { useEffect } from "react";

export default function Terms() {

  useEffect(() => {
    // Store the original background color
    const originalBackgroundColor = document.body.style.backgroundColor;
    // Change the body’s background to blue
    document.body.style.backgroundColor = "#aacafb";

    // On unmount, revert the body’s background
    return () => {
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, []);
  
  return (
    <div id="termsBody">
       <h1>TERMS OF USE</h1>
      <p>
        <strong>Last updated January 31, 2025</strong>
      </p>

      <h2>AGREEMENT TO OUR LEGAL TERMS</h2>
      <p>
        We are Language Simp Inc. (“Company,” “we,” “us,” “our”), a company
        registered in Pennsylvania, United States. Our address is 239 Fourth
        Ave, Pittsburgh, PA 15222. We operate the website{' '}
        <a href="http://www.languagesimp.com">http://www.languagesimp.com</a>{' '}
        (the “Site”), as well as other related products and services that refer
        or link to these legal terms (collectively, the “Services”).
      </p>
      <p>
        You can contact us by email at{' '}
        <a href="mailto:contact@languagesimp.com">contact@languagesimp.com</a>{' '}
        or by mail at 239 Fourth Ave, Pittsburgh, PA 15222, United States.
      </p>
      <p>
        These Terms of Use (“Legal Terms”) constitute a legally binding
        agreement made between you, whether personally or on behalf of an entity
        (“you”), and Language Simp Inc. concerning your access to and use of the
        Services. By accessing the Services, you agree to be bound by these
        Legal Terms. If you do not agree with all of these Legal Terms, you are
        expressly prohibited from using the Services and must discontinue use
        immediately.
      </p>
      <p>
        We reserve the right to make changes or modifications to these Legal
        Terms at any time. We will alert you of any changes by updating the
        “Last updated” date of these Legal Terms. It is your responsibility to
        review these Legal Terms to stay informed of updates. By continuing to
        use the Services after the date such revised Legal Terms are posted, you
        will be deemed to have accepted the changes.
      </p>
      <p>
        The Services are intended for users who are at least 18 years old.
        Persons under the age of 18 are not permitted to use or register for the
        Services.
      </p>
      <p>We recommend printing a copy of these Legal Terms for your records.</p>

      <h2>TABLE OF CONTENTS</h2>
      <ol>
        <li>OUR SERVICES</li>
        <li>INTELLECTUAL PROPERTY RIGHTS</li>
        <li>USER REPRESENTATIONS</li>
        <li>PRODUCTS</li>
        <li>PURCHASES AND PAYMENT</li>
        <li>REFUNDS POLICY</li>
        <li>PROHIBITED ACTIVITIES</li>
        <li>USER GENERATED CONTRIBUTIONS</li>
        <li>CONTRIBUTION LICENSE</li>
        <li>THIRD-PARTY WEBSITES AND CONTENT</li>
        <li>SERVICES MANAGEMENT</li>
        <li>PRIVACY POLICY</li>
        <li>TERM AND TERMINATION</li>
        <li>MODIFICATIONS AND INTERRUPTIONS</li>
        <li>GOVERNING LAW</li>
        <li>DISPUTE RESOLUTION</li>
        <li>CORRECTIONS</li>
        <li>DISCLAIMER</li>
        <li>LIMITATIONS OF LIABILITY</li>
        <li>INDEMNIFICATION</li>
        <li>USER DATA</li>
        <li>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</li>
        <li>CALIFORNIA USERS AND RESIDENTS</li>
        <li>MISCELLANEOUS</li>
        <li>CONTACT US</li>
      </ol>

      <h2>1. OUR SERVICES</h2>
      <p>
        The information provided when using the Services is not intended for
        distribution to or use by any person or entity in any jurisdiction or
        country where such distribution or use would be contrary to law or
        regulation. Those who choose to access the Services from other locations
        do so on their own initiative and are solely responsible for compliance
        with local laws if and to the extent they apply.
      </p>
      <p>
        The Services are not tailored to comply with industry-specific
        regulations (e.g., HIPAA, FISMA). Therefore, if your interactions would
        be subjected to such laws, you may not use the Services.
      </p>

      <h2>2. INTELLECTUAL PROPERTY RIGHTS</h2>
      <h3>Our Intellectual Property</h3>
      <p>
        We own or license all intellectual property rights in our Services,
        including source code, databases, functionality, software, designs,
        audio, video, text, photographs, and graphics (“Content”), as well as
        the trademarks, service marks, and logos contained therein (“Marks”).
        The Content and Marks are protected by copyright and trademark laws in
        the United States and around the world. The Content and Marks are
        provided “AS IS” for your personal, non-commercial use.
      </p>
      <h3>Your Use of Our Services</h3>
      <p>
        Subject to your compliance with these Legal Terms, we grant you a
        non-exclusive, non-transferable, revocable license to:
      </p>
      <ul>
        <li>Access the Services, and</li>
        <li>Download or print a copy of any portion of the Content you have
          properly gained access to, solely for your personal, non-commercial
          use.
        </li>
      </ul>
      <p>
        No part of the Services, Content, or Marks may be copied, reproduced,
        aggregated, republished, uploaded, posted, publicly displayed, encoded,
        or translated without our express prior written permission. If you wish
        to make any other use of the Services or Content, please contact us at{' '}
        <a href="mailto:contact@languagesimp.com">contact@languagesimp.com</a>.
      </p>
      <p>
        Any breach of these Intellectual Property Rights terminates your right
        to use our Services immediately.
      </p>
      <h3>Your Submissions</h3>
      <p>
        By sending us any questions, comments, suggestions, feedback, or other
        information about the Services (“Submissions”), you assign all
        intellectual property rights in such Submissions to us. You also confirm
        that the Submissions do not violate the rights of any third party and
        that you have the authority to send them.
      </p>

      <h2>3. USER REPRESENTATIONS</h2>
      <p>
        By using the Services, you represent and warrant that (1) you have the
        legal capacity to comply with these Legal Terms; (2) you are not a
        minor; (3) you will not access the Services through automated or
        non-human means; (4) you will not use the Services for any illegal or
        unauthorized purpose; and (5) your use of the Services will not violate
        any applicable law or regulation.
      </p>
      <p>
        If you provide any information that is untrue or incomplete, we may
        suspend or terminate your account and refuse any and all current or
        future use of the Services.
      </p>

      <h2>4. PRODUCTS</h2>
      <p>
        All products are subject to availability. We reserve the right to
        discontinue any product at any time for any reason. Prices are also
        subject to change.
      </p>

      <h2>5. PURCHASES AND PAYMENT</h2>
      <p>We accept the following forms of payment:</p>
      <ul>
        <li>Lemon Squeezy</li>
      </ul>
      <p>
        You agree to provide current, complete, and accurate purchase and
        account information for all purchases made via the Services. Sales tax
        will be added as required. Payments are in US dollars. We reserve the
        right to correct any errors in pricing even if we have already requested
        payment. We also reserve the right to refuse or cancel orders at our
        discretion, including those that appear to be placed by dealers,
        resellers, or distributors.
      </p>

      <h2>6. REFUNDS POLICY</h2>
      <p>
        Depending on jurisdiction, we typically offer returns and refunds up to
        14 days after purchase. Proof of purchase is required. For any issues,
        returns, or refunds, please email{' '}
        <a href="mailto:support@languagesimp.com">support@languagesimp.com</a>.
      </p>

      <h2>7. PROHIBITED ACTIVITIES</h2>
      <p>
        You may not access or use the Services for any purpose other than that
        for which we make the Services available. This includes, but is not
        limited to, systematically collecting data, tricking or misleading other
        users, circumventing security features, harassing or threatening others,
        uploading viruses or harmful content, or using automated systems (like
        bots) without our permission. A full list of prohibited activities is
        outlined in the original text. Violation of any prohibited activity may
        result in suspension or termination of your use of the Services.
      </p>

      <h2>8. USER GENERATED CONTRIBUTIONS</h2>
      <p>
        The Services may allow you to submit or post content, including text,
        writings, video, audio, photographs, comments, or other materials
        (“Contributions”). You agree that any such Contributions you create or
        submit comply with our requirements and do not violate any
        laws/regulations or third-party rights. You retain ownership over your
        own Contributions, but by submitting them, you grant us certain rights
        to store, display, and use them as outlined in these Legal Terms and our
        Privacy Policy.
      </p>

      <h2>9. CONTRIBUTION LICENSE</h2>
      <p>
        By submitting any Contributions, you allow us to access, store, process,
        and use them per the Privacy Policy. You also agree we can use and share
        any feedback (such as suggestions) you provide, without compensation to
        you.
      </p>
      <p>
        We do not claim ownership over your Contributions, but you agree you are
        solely responsible for them and that you will not hold us liable for
        anything regarding your Contributions.
      </p>

      <h2>10. THIRD-PARTY WEBSITES AND CONTENT</h2>
      <p>
        The Services may contain links to third-party websites or content. We do
        not monitor or check these websites or content for accuracy or
        completeness. Any interaction, purchase, or use of such third-party
        websites or content is at your own risk. We are not liable for any harm
        caused by such third-party websites or content.
      </p>

      <h2>11. SERVICES MANAGEMENT</h2>
      <p>
        We reserve the right to monitor the Services for violations, enforce
        these Legal Terms, refuse or restrict access, remove or disable
        inappropriate content, and otherwise manage the Services to protect our
        rights and maintain proper functioning.
      </p>

      <h2>12. PRIVACY POLICY</h2>
      <p>
        We care about data privacy and security. Please review our{' '}
        <a href="http://www.languagesimp.com/privacy">
          Privacy Policy
        </a>
        . By using the Services, you agree to be bound by it. The Services are
        hosted in the United States; by continuing to use the Services from
        outside the US, you consent to your data being transferred to and
        processed in the US.
      </p>

      <h2>13. TERM AND TERMINATION</h2>
      <p>
        These Legal Terms remain in effect while you use the Services. We may
        suspend or terminate your access to the Services at any time for any
        reason, including violation of these Legal Terms or any law/regulation.
        If your account is terminated, you are prohibited from creating another
        account under a fake or borrowed name.
      </p>

      <h2>14. MODIFICATIONS AND INTERRUPTIONS</h2>
      <p>
        We reserve the right to change, modify, or remove the contents of the
        Services at any time without notice. We also reserve the right to modify
        or discontinue all or part of the Services without notice. We are not
        liable for any downtime or discontinuance.
      </p>

      <h2>15. GOVERNING LAW</h2>
      <p>
        These Legal Terms and your use of the Services are governed by the laws
        of the Commonwealth of Pennsylvania without regard to conflict of law
        principles.
      </p>

      <h2>16. DISPUTE RESOLUTION</h2>
      <h3>Informal Negotiations</h3>
      <p>
        The Parties agree to first attempt to negotiate any Dispute (except
        those expressly provided below) informally for at least 30 days before
        initiating arbitration.
      </p>
      <h3>Binding Arbitration</h3>
      <p>
        If the Parties cannot resolve a Dispute via informal negotiations, it
        will be resolved by binding arbitration under the AAA’s Commercial
        Arbitration Rules. If a Dispute proceeds in court rather than
        arbitration, such Dispute will be brought in state or federal courts
        located in Allegheny County, Pennsylvania.
      </p>
      <h3>Restrictions</h3>
      <p>
        Arbitration shall be limited to the individual dispute between the
        Parties. No class actions or representative actions are permitted.
      </p>
      <p>
        Exceptions to the above include claims related to enforcement of
        intellectual property rights and certain other allegations (e.g., theft,
        piracy).
      </p>

      <h2>17. CORRECTIONS</h2>
      <p>
        There may be information on the Services that contains typographical
        errors or omissions. We reserve the right to correct them at any time
        without prior notice.
      </p>

      <h2>18. DISCLAIMER</h2>
      <p>
        THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.” YOUR USE OF THE
        SERVICES IS AT YOUR OWN RISK. WE DISCLAIM ALL WARRANTIES, EXPRESS OR
        IMPLIED. WE MAKE NO GUARANTEES ABOUT THE ACCURACY OR COMPLETENESS OF OUR
        CONTENT OR THAT OF ANY THIRD-PARTY LINKED SITES.
      </p>

      <h2>19. LIMITATIONS OF LIABILITY</h2>
      <p>
        WE SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
        INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
        DAMAGES, INCLUDING LOST PROFIT OR DATA, ARISING FROM YOUR USE OF THE
        SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES. SOME STATES OR COUNTRIES DO NOT ALLOW THIS LIMITATION, SO IT
        MAY NOT APPLY TO YOU.
      </p>

      <h2>20. INDEMNIFICATION</h2>
      <p>
        You agree to defend and indemnify us and our affiliates from any loss,
        damage, or liability arising out of your use of the Services, breach of
        these Terms, or violation of any rights of a third party.
      </p>

      <h2>21. USER DATA</h2>
      <p>
        We will maintain certain data that you transmit to manage the Services,
        but you are solely responsible for all data you transmit or any activity
        you have undertaken using the Services. We are not liable for data loss
        or corruption.
      </p>

      <h2>22. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h2>
      <p>
        By using the Services, you consent to receive electronic communications
        and agree that all agreements, notices, and records provided via email
        or on the Services satisfy any legal requirement that such
        communication be in writing.
      </p>

      <h2>23. CALIFORNIA USERS AND RESIDENTS</h2>
      <p>
        If a complaint with us is not resolved, you can contact the Complaint
        Assistance Unit of the Division of Consumer Services of the California
        Department of Consumer Affairs at 1625 North Market Blvd., Suite N 112,
        Sacramento, California 95834 or (800) 952-5210 or (916) 445-1254.
      </p>

      <h2>24. MISCELLANEOUS</h2>
      <p>
        These Legal Terms constitute the entire agreement between you and us.
        Our failure to exercise or enforce any right does not operate as a
        waiver. We may assign our rights and obligations to others. If any part
        of these Legal Terms is unlawful or unenforceable, that part is severed
        and does not affect the validity of the remaining provisions.
      </p>

      <h2>25. CONTACT US</h2>
      <p>
        If you have any questions or concerns, please contact us at:
      </p>
      <address>
        Language Simp Inc. <br />
        239 Fourth Ave <br />
        Pittsburgh, PA 15222 <br />
        United States <br />
        <a href="mailto:contact@languagesimp.com">contact@languagesimp.com</a>
      </address>
    </div>
  );
}
