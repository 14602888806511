import React, { useEffect } from "react";

export default function Privacy() {

  useEffect(() => {
    // Store the original background color
    const originalBackgroundColor = document.body.style.backgroundColor;
    // Change the body’s background to blue
    document.body.style.backgroundColor = "#aacafb";

    // On unmount, revert the body’s background
    return () => {
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, []);
  
  return (
    <div id="termsBody">
       <h1>PRIVACY POLICY</h1>
      <p><strong>Last updated January 31, 2025</strong></p>

      <p>
        This Privacy Policy explains how Language Simp Inc. (“we,” “us,” or “our”) may access, collect, store, use, and/or share (“process”) your personal information when you use our services (“Services”). This includes:
      </p>

      <ul>
        <li>Visiting our website at <a href="http://www.languagesimp.com">http://www.languagesimp.com</a> or any website that links to this Privacy Policy</li>
        <li>Engaging with us in any related ways (sales, marketing, events, etc.)</li>
      </ul>

      <p>
        If you have any questions or concerns, please contact us at <a href="mailto:contact@languagesimp.com">contact@languagesimp.com</a>.
      </p>

      <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
      <h3>Personal Information You Disclose to Us</h3>
      <p>
        We collect personal information that you voluntarily provide when you request information about us or our products and services, participate in activities through our Services, or otherwise contact us. This may include:
      </p>
      <ul>
        <li>Email addresses</li>
      </ul>
      <p>
        Payment information (such as payment card numbers) may be collected if you make purchases through our site, and is stored and processed by payment processors like Lemon Squeezy. We do not process “sensitive” personal information.
      </p>

      <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
      <p>
        We process personal information to provide, improve, and administer our Services, communicate with you, prevent security and fraud, and comply with legal requirements. Specifically, we may process personal information to:
      </p>
      <ul>
        <li>Provide requested services and fulfill orders</li>
        <li>Respond to user inquiries and offer support</li>
        <li>Send administrative and promotional information</li>
        <li>Process your payments and handle returns/exchanges</li>
        <li>Safeguard our legitimate interests or vital interests, such as preventing harm</li>
      </ul>

      <h2>3. WHAT LEGAL BASES DO WE RELY ON?</h2>
      <p>
        We only process personal information where we have a legal basis to do so (e.g., with your consent, to comply with laws, to provide or fulfill contractual obligations, or for our legitimate business interests).
      </p>

      <h2>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
      <p>
        We may share your data with third-party vendors, service providers, contractors, or agents (“third parties”) who perform services on our behalf and require access to that information. These include:
      </p>
      <ul>
        <li>
          <strong>Communications:</strong> MailChimp/Intuit (
          <a href="https://www.intuit.com/privacy/statement/">https://www.intuit.com/privacy/statement/</a>)
        </li>
        <li>
          <strong>Billing:</strong> Lemon Squeezy (
          <a href="https://www.lemonsqueezy.com/privacy">https://www.lemonsqueezy.com/privacy</a>)
        </li>
      </ul>
      <p>
        We may also share or transfer your information in connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
      </p>

      <h2>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
      <p>
        We keep your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless otherwise required by law.
      </p>

      <h2>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
      <p>
        We use technical and organizational security measures to protect your personal information. However, no electronic system is 100% secure. Use our Services at your own risk and ensure you use a secure environment.
      </p>

      <h2>7. DO WE COLLECT INFORMATION FROM MINORS?</h2>
      <p>
        We do not knowingly collect or market to children under 18 years of age. If we learn of any data collected from children under 18, we will promptly delete it. Please contact us if you become aware of such data.
      </p>

      <h2>8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
      <p>
        Depending on where you reside, you may have certain rights under applicable privacy laws (e.g., to request access to or deletion of your information). You can exercise these rights by contacting us at <a href="mailto:contact@languagesimp.com">contact@languagesimp.com</a>.
      </p>

      <h2>9. DO-NOT-TRACK FEATURES</h2>
      <p>
        Most browsers include a Do-Not-Track setting. Currently, no uniform technology standard recognizes DNT signals, so we do not respond to them. If a standard emerges that we must follow, we will update this policy.
      </p>

      <h2>10. UNITED STATES RESIDENTS’ PRIVACY RIGHTS</h2>
      <p>
        If you reside in certain US states (such as California, Colorado, etc.), you may have specific rights under your state’s privacy laws. This may include the right to access, correct, or delete certain personal information, and to opt out of sharing information for targeted advertising. You can make such requests by emailing us at <a href="mailto:contact@languagesimp.com">contact@languagesimp.com</a>.
      </p>

      <h2>11. OTHER REGIONS’ PRIVACY RIGHTS</h2>
      <p>
        If you reside in other countries (e.g., in the EEA, UK, Canada, Australia, New Zealand, South Africa), you may have additional privacy rights under those laws. Contact us for more information or to exercise these rights.
      </p>

      <h2>12. DO WE MAKE UPDATES TO THIS POLICY?</h2>
      <p>
        We may update this Privacy Policy from time to time. The updated version will be indicated by the “Last updated” date. We encourage you to review this Privacy Policy frequently to stay informed about how we protect your information.
      </p>

      <h2>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
      <p>
        If you have questions about this Privacy Policy, please email us at{' '}
        <a href="mailto:contact@languagesimp.com">contact@languagesimp.com</a> or send mail to:
      </p>
      <address>
        Language Simp Inc.<br />
        239 Fourth Ave, Ste 1401 #3968<br />
        Pittsburgh, PA 15222<br />
        United States
      </address>

      <h2>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE YOUR DATA?</h2>
      <p>
        Depending on your location, you may request to review, update, or delete the information we collect from you. Please email us at{' '}
        <a href="mailto:contact@languagesimp.com">contact@languagesimp.com</a>.
      </p>
    </div>
  );
}
